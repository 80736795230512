import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["exportLink"]

    connect() {
        console.log("GL Account Export Filter controller connected")
    }

    visitAccount(event) {
        const accountId = event.target.value
        if (!accountId) return

        const startDate = document.querySelector('[name="start_date"]').value
        const endDate = document.querySelector('[name="end_date"]').value
        const itemsPerPage = document.querySelector('[name="items_per_page"]').value

        const url = new URL(`/gl_accounts/${accountId}`, window.location.origin)
        url.searchParams.set('start_date', startDate)
        url.searchParams.set('end_date', endDate)
        url.searchParams.set('items_per_page', itemsPerPage)

        Turbo.visit(url.toString())
    }


    updateExportUrl(event) {
        const startDate = this.element.querySelector('[name="start_date"]').value
        const endDate = this.element.querySelector('[name="end_date"]').value
        const currentHref = this.exportLinkTarget.href
        const url = new URL(currentHref)

        url.searchParams.set('start_date', startDate)
        url.searchParams.set('end_date', endDate)

        this.exportLinkTarget.href = url.toString()
    }
}