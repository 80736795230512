import {Controller} from "stimulus"

var delayTimer;
export default class extends Controller {

    connect() {
        console.log("hello from reports-budget-analysis controller");
    }

    updateReport(e) {
        console.log("report changed")
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            const dateFrom = document.getElementById("date_from")
            const dateTo = document.getElementById("date_to")
            if (dateFrom.value != "" && dateTo.value != "" || dateFrom.value == "" && dateTo.value == "") {
                document.getElementById("flash-area").innerHTML = ""
                let title = "Budget Analysis: "
                if (dateFrom.value != "") {
                    title += dateFrom.value + " to " + dateTo.value;
                } else {
                    title += "Month to Date";
                }
                let reportUrl = 'chart?title=' + title +
                    '&data_source=reports_budget_analysis_path&date_from=' + dateFrom.value +
                    '&date_to=' + dateTo.value
                window.location.href = reportUrl;
            } else {
                document.getElementById("flash-area").innerHTML = ('<div  class="warn">Please select a date range or leave both fields blank.</div>')
                dateFrom.classList.add('required-field')
                dateTo.classList.add('required-field')
            }

        }, 2500)


    }
}