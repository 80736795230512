import { Controller } from "stimulus";
import Sortable from "sortablejs";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  initialize() {
    this.onEnd = this.onEnd.bind(this);
  }

  connect() {
    if (this.sortable === undefined) {
      this.sortable = new Sortable(this.element, {
        group: this.groupValue,
        handle: ".sortable-handle",
        animation: 250,
        fallbackOnBody: true,
        swapThreshold: 1,
        onEnd: this.onEnd,
      });
    }
  }

  disconnect() {
    // FIXME: Destroying sortable instance on disconnect is causing drag and drop to stop working
    // this.sortable.destroy();
    // this.sortable = undefined;
  }

  async onEnd({ item, to, newIndex }) {
    let formData = new FormData();
    if (item && item.dataset.sortableItemGid != undefined) { formData.append("item_gid", item.dataset.sortableItemGid) };
    if (to && to.dataset.sortableParentGid != undefined) { formData.append("parent_gid", to.dataset.sortableParentGid) };
    formData.append("position", newIndex + 1)

    const request = new FetchRequest("post", this.urlValue, { body: formData });
    const response = await request.perform();
    if (response.ok) { this.responseValue = "ok" };
  }

  // TODO: Switch to using Stimulus Values when upgrading past Stimulus v1
  get groupValue() {
    return this.element.dataset.nestedSortableGroupValue;
  }

  // TODO: Switch to using Stimulus Values when upgrading past Stimulus v1
  get urlValue() {
    return this.element.dataset.nestedSortableUrlValue;
  }
}
