import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

export default class extends Controller {

    connect() {
        console.log("log from bl-check-requests controller");
    }


    initializeNewForm() {
        const amountField = document.querySelector("#bl_check_request_amount");
        const dateField = document.querySelector("#bl_check_request_when_booked");
        if (!amountField) {
            console.error("Amount field not found");
            return;
        }

        console.log("Found amount field:", amountField);

        if (AutoNumeric.isManagedByAutoNumeric(amountField)) {
            console.log("Removing existing AutoNumeric instance");
            AutoNumeric.getAutoNumericElement(amountField).remove();
        }

        // Create new instance
        console.log("Creating new AutoNumeric instance");
        new AutoNumeric(amountField, {
            currencySymbol: '$',
            decimalPlaces: 2
        });
        setTimeout(() => {
            dateField.addEventListener("change", this.postButtonState.bind(this));
            amountField.addEventListener("keyup", this.postButtonState.bind(this));
        }, 0);

    }
    postButtonState(e) {
        console.log('checking bl check request form readiness')
        const formArea = e.target.closest("#request-refund").querySelector('.bl-request-form');
        const amountField = formArea.querySelector("#bl_check_request_amount");
        const setAmount = amountField.value;

        // First check if AutoNumeric is initialized
        if (AutoNumeric.isManagedByAutoNumeric(amountField)) {
            AutoNumeric.set(amountField, setAmount);
            const memoAmountPresent = AutoNumeric.getNumber(amountField);
            const dateSelectPresent = formArea.querySelector("#bl_check_request_when_booked").value.length > 0;
            const memoSubmitButton = formArea.querySelector(".button");
            memoSubmitButton.disabled = !(dateSelectPresent && memoAmountPresent);
        } else {
            // Handle case where AutoNumeric isn't initialized yet
            const dateSelectPresent = formArea.querySelector("#bl_check_request_when_booked").value.length > 0;
            const memoSubmitButton = formArea.querySelector(".button");
            memoSubmitButton.disabled = !(dateSelectPresent && setAmount);
        }
    }

    handleSubmitEnd(event) {
        if (event.detail.success) {
            this.initializeNewForm();
        }
    }


}
