// payment_controller.js
import {Controller} from "stimulus"

export default class extends Controller {
    static targets = []

    connect() {
    }

    showSummary() {
        const selectedPayments = document.querySelectorAll('.payment-select')
        const paymentsByMethod = {}

        selectedPayments.forEach(select => {
            if (select.value && select.value !== '' && select.value !== 'schedule_later') {
                const method = select.value
                const amount = parseFloat(select.dataset.paymentAmount)
                const vendorId = select.dataset.vendorId
                const paymentId = select.dataset.paymentId

                if (!paymentsByMethod[method]) {
                    paymentsByMethod[method] = {
                        total: 0,
                        payments: [],
                        count: 0,
                        vendors: new Set()
                    }
                }

                paymentsByMethod[method].total += amount
                paymentsByMethod[method].payments.push(paymentId)
                paymentsByMethod[method].count++
                paymentsByMethod[method].vendors.add(vendorId)
            }
        })

        // Create a more detailed summary
        const summaryHTML = Object.entries(paymentsByMethod)
            .map(([method, data]) => `
                <div class="border-b pb-4 mb-4">
                    <h4 class="font-medium mb-2">
                        ${method}
                    </h4>
                    <div class="text-sm text-gray-600">
                        Number of Payments: ${data.count}<br>
                        Number of ${method}s: ${data.vendors.size}<br>
                        Total Amount: ${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(data.total)}
                    </div>
                </div>
            `).join('')

        document.getElementById('payment-summary').innerHTML = summaryHTML

        // Add hidden inputs for form submission
        const selectedPaymentsDiv = document.getElementById('selected-payments')
        selectedPaymentsDiv.innerHTML = Object.entries(paymentsByMethod)
            .flatMap(([method, data]) =>
                data.payments.map(paymentId =>
                    `<input type="hidden" name="payments[${paymentId}]" value="${method}">`
                )
            ).join('')
    }
}