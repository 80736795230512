import {Controller} from "stimulus"
import AutoNumeric from "autonumeric";

let autoNumericList = [];

export default class extends Controller {

    connect() {
        console.log("log from reports controller");
        const data_source = this.data.get("data-source")
        let table_param = "?type=table"
        if (data_source.includes('?')) {
            table_param = "&type=table"
        }
        fetch(this.data.get("data-source") + table_param)
            .then(response => {
                return response.text();
            })
            .then(jsonData => {
                const parsedData = JSON.parse(jsonData);
                if (parsedData.length > 0) {
                    document.querySelector(".report-chart-area").innerHTML = json2table(parsedData);
                }
            });

    }


}

// from https://travishorn.com/building-json2table-turn-json-into-an-html-table-a57cf642b84a
function json2table(json, classes) {
    var cols = Object.keys(json[0]);

    var headerRow = '';
    var bodyRows = '';

    classes = classes || 'table striped-table';

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    cols.map(function (col) {
        headerRow += '<th>' + capitalizeFirstLetter(col) + '</th>';
    });

    json.map(function (row) {
        bodyRows += '<tr>';

        cols.map(function (colName) {
            bodyRows += '<td>' + row[colName] + '</td>';
        })

        bodyRows += '</tr>';
    });

    return '<table class="' +
        classes +
        '"><thead><tr>' +
        headerRow +
        '</tr></thead><tbody>' +
        bodyRows +
        '</tbody></table>';
}
